//src/api/brandapi.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
/*广告接口---------------------------*/
//获取广告列表接口
export function getmessageData(obj) {
    return request({
        url: '/api/admin/message',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增广告接口
export function postaddmessage(obj,url) {
    return request({
        url: '/api/admin/message/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除广告接口
export function postdelmessage(obj,url) {
    return request({
        url: '/api/admin/message/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}


